const breakpoints = [576, 768, 992, 1200]
const customMediaQuery = (pixels: number) => `@media (min-width: ${pixels}px)`

const black = '#373E4D'
const blue = '#2E5CA9'
const blueLight = '#F1F5F8'
const blueDeep = '#003399'
const coral = '#F38374'
const white = '#fff'

export default {
  colors: {
    primary: blue,
    secondary: coral,
    black,
    blue,
    blueLight,
    blueDeep,
    coral,
    white,
    background: white,
    text: black,
  },
  fontWeights: {
    body: 400,
    medium: 600,
    bold: 700,
    heading: 700,
  },
  sizes: {
    navDesktop: 72,
    navMobile: 50,
    mobileContainerGutter: 3,
  },
  heights: {
    nav: `72px`,
    navMobile: '50px',
  },
  zIndices: {
    nav: 10,
  },
  z: {
    nav: 10,
  },
  radii: {
    small: 4,
    mid: 8,
    large: 64,
  },
  breakpoints: breakpoints.map(point => `${point}px`),
  cmq: customMediaQuery,
  mq: breakpoints.map(bp => `@media (min-width: ${bp}px)`),
  fonts: {
    default: 'Basis Grotesque Pro, Barlow',
    numeric: 'Barlow',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.13,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}
